import { RedirectWithQuery } from '@xylabs/react-common'
// import { PixelDebugger } from '@xylabs/react-pixel-debugger'
import { useCoinUserEvents } from '@xylabs/sdk-coin-react'
import { RouteDomain, routeDomain } from '@xylabs/sdk-coinapp.co-shared-react'
import { forget } from '@xylabs/sdk-js'
import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const GoCoinAppCustomRoutes = lazy(() => import(/* webpackChunkName: "gocoinapp" */ './GoCoinAppCustomRoutes'))

const CheckoutRoutes = lazy(() => import(/* webpackChunkName: "checkout", webpackPrefetch: true */ './CheckoutRoutes'))

const LandingRoutes = lazy(() => import(/* webpackChunkName: "landing" */ './LandingRoutes'))

const SiteRoutes = lazy(() => import(/* webpackChunkName: "site" */ './SiteRoutes'))

const LazyGoCoinAppCustomRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <GoCoinAppCustomRoutes />
    </Suspense>
  )
}

const LazyCheckoutRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <CheckoutRoutes />
    </Suspense>
  )
}

const LazyLandingRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <LandingRoutes />
    </Suspense>
  )
}

const LazySiteRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <SiteRoutes />
    </Suspense>
  )
}

const AppRouter: React.FC = () => {
  const userEvents = useCoinUserEvents()
  forget(userEvents?.pageView(window.location.href))

  if (routeDomain === RouteDomain.GiddyUp) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<RedirectWithQuery to="/checkout/thankyou" />} path={'/thankyou'} />
          <Route element={<RedirectWithQuery to="/checkout/3pack" />} path={'/3pack'} />
          <Route element={<LazyCheckoutRoutes />} path={'/checkout/*'} />
          <Route element={<LazyGoCoinAppCustomRoutes />} path="*" />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RedirectWithQuery to="/checkout/thankyou" />} path={'/thankyou'} />
        <Route element={<RedirectWithQuery to="/checkout/3pack" />} path={'/3pack'} />
        <Route element={<RedirectWithQuery to="/checkout/1nfc" />} path={'/1nfc'} />
        <Route element={<LazyLandingRoutes />} path={'/free-nfc'} />
        <Route element={<LazyLandingRoutes />} path={'/free-nfc/*'} />
        {/* delete /free/ after new cloud front rule */}
        <Route element={<LazyLandingRoutes />} path={'/free'} />
        <Route element={<LazyLandingRoutes />} path={'/free/*'} />
        {/* dend*/}
        <Route element={<LazyCheckoutRoutes />} path={'/checkout'} />
        <Route element={<LazyCheckoutRoutes />} path={'/checkout/*'} />
        <Route element={<LazySiteRoutes />} path="/*" />
      </Routes>
      {/*{isNotProduction && <PixelDebugger />}*/}
    </BrowserRouter>
  )
}

export default AppRouter
