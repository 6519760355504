enum RouteDomain {
  Standard,
  GiddyUp,
}

const routeDomain = window.location.hostname.endsWith('gocoinapp.com') //|| window.location.hostname.endsWith('localhost')
  ? RouteDomain.GiddyUp
  : RouteDomain.Standard

export { RouteDomain, routeDomain }
