import { delay } from '@xylabs/sdk-js'

//the globalLoadField is used to check to see if the script loaded by checking for the existance of that field
const loadScript = async <T>(url: string, globalLoadField: string) => {
  const script = document.createElement('script')

  script.src = url
  script.async = true

  document.body.appendChild(script)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any
  while (global[globalLoadField] === undefined) {
    await delay(100)
  }
  return global[globalLoadField] as T
}

export default loadScript
