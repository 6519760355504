import { useAsyncEffect } from '@xylabs/react-shared'
import { useState } from 'react'

import loadScript from './loadScript'
import { EF } from './types'

export enum GiddyeUpEvent {
  HomePage = 12801,
  Contact = 12802,
  Address = 12804,
  Payment = 12803,
  Conversion = 1,
}

export const useGiddyUp = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any
  const [ef, setEf] = useState<EF | null>(global.EF)
  const isGiddyUp = window.location.hostname.endsWith('gocoinapp.com')

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      if (ef === undefined) {
        let newEf: EF | null = null
        if (isGiddyUp) {
          newEf = await loadScript<EF>('https://www.gu-ecom.com/scripts/sdk/everflow.js', 'EF')
          console.log('GU Loaded')
        }
        if (mounted()) {
          setEf(newEf)
        }
      }
    },
    [ef, isGiddyUp],
  )

  return ef === undefined
    ? undefined
    : (event: GiddyeUpEvent, orderNumber?: string, amount?: number) => {
        if (event === GiddyeUpEvent.Conversion) {
          ef?.conversion({
            adv1: orderNumber,
            amount,
            nid: 373,
            offer_id: 583,
            transaction_id: ef.urlParameter('req_id'),
          })
        } else {
          ef?.conversion({
            adv_event_id: event,
            aid: 128,
            nid: 373,
            oid: 583,
            transaction_id: ef.urlParameter('req_id'),
          })
        }
      }
}
