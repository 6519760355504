import 'intersection-observer'

import { CssBaseline, ThemeOptions } from '@mui/material'
import { PixelApi, XyPixel } from '@xylabs/pixel'
import { FlexCol } from '@xylabs/react-flexbox'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { Fbq, Gtag, Rdt, Ttq, UserEventsProvider } from '@xylabs/react-pixel'
// import { PixelDebuggerProvider } from '@xylabs/react-pixel-debugger'
import { checkGoCoinApp, themeOptions, UserEvents } from '@xylabs/sdk-coin-react'
import { GiddyeUpEvent, log, useGiddyUp } from '@xylabs/sdk-coinapp.co-shared-react'
import { merge } from 'lodash-es'
import { Helmet } from 'react-helmet'

import { AppRouter } from './AppRouter'

const checkIFrame = () => {
  if (window.self !== window.top) {
    log.warn('checkIFrame', {
      location: window.top?.location?.href ?? 'Unknown',
    })
    return true
  }
  return false
}

const initXyPixel = () => {
  switch (document.location.hostname) {
    case 'localhost':
      XyPixel.selectApi(new PixelApi('local'))
      break
    case 'beta.coinapp.co':
      XyPixel.selectApi(new PixelApi('beta'))
      break
    default:
      XyPixel.selectApi(new PixelApi('prod'))
      break
  }
  XyPixel.init(document.location.hostname)
}

const initPixels = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const global = window as any
  try {
    initXyPixel()
    Fbq.init('339003476728548')
    Gtag.init('G-TCJLRQCJ2H', 'AW-989965544', ['coinapp.co', 'localhost', 'localhost:3000'])
    if (global.pixels.tiktok) {
      Ttq.init('CBC6ISRC77U9C04LJ2KG')
    }
    if (global.pixels.reddit) {
      Rdt.init('t2_3qsbqscf')
    }
  } catch (ex) {
    log.error(`initPixels: ${ex}`)
  }
}

const App: React.FC = () => {
  const giddyUp = useGiddyUp()
  if (checkIFrame()) {
    setTimeout(() => {
      if (top) {
        top.location.replace(self.location.href)
      }
    }, 500)
  }

  initPixels()
  checkGoCoinApp(top?.location.hostname === 'beta.gocoinapp.com' ? 'http://website.go2cloud.org/aff_c?offer_id=3&aff_id=1001' : undefined)

  giddyUp?.(GiddyeUpEvent.HomePage)

  const additionalThemeOptions: ThemeOptions = {
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
    },
  }

  return (
    <UserEventsProvider userEvents={UserEvents.get()}>
      <InvertibleThemeProvider options={merge(themeOptions, additionalThemeOptions)}>
        <CssBaseline />
        <FlexCol alignItems="stretch" background>
          <Helmet>
            <meta charSet="utf-8" />
            <title>COIN: Always Earning</title>
            <meta content="index, follow" name="robots" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-TCJLRQCJ2H" />
            <script async src="https://connect.facebook.net/en_US/fbevents.js" />
          </Helmet>
          <AppRouter />
        </FlexCol>
      </InvertibleThemeProvider>
    </UserEventsProvider>
  )
}

export default App
